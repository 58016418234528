@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
/*        font-family: "Open Sans, sans-serif;";*/

    }
}

@keyframes bounceUp {
    0% {
        transform: translateY(0);
    }
    80% {
        transform: translateY(-8px);
    }
    100% {
        transform: translateY(-6px);
    }
}

@keyframes bounceDown {
    0% {
        transform: translateY(-6px);
    }
    80% {
        transform: translateY(1px);
    }
    100% {
        transform: translateY(0px);
    }
}

.bouncy-button.bouncy-button-out {
    animation: bounceDown 0.2s ease-out;
    transform: translateY(-0px);
}

.bouncy-button.bouncy-button-in {
    animation: bounceUp 0.2s ease-out;
    transform: translateY(-6px);
}

.bouncy-button-active {
    transform: translateY(-3px) !important;
}


.loading-panel {
    opacity: 100%;
}

.loading-panel.fade-out {
    animation: fadeOut 0.5s ease-out;
    opacity: 0%;
}

@keyframes fadeOut {
    0% {
        opacity: 100%;
    }
    100% {
        opacity: 0%;
    }
}

.results-panel {
    opacity: 0%;
}

.results-panel.fade-in {
    animation: fadeIn 0.5s ease-out;
    opacity: 100%;
}

@keyframes fadeIn {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

/*********************/
/*Box Animation*/
/*********************/
.scene {
    width: 200px;
    height: 200px;
    perspective: 1000px;
    position: relative;
    margin: 100px auto;
}

.box {
    width: 100px;
    height: 100px;
    position: relative;
    transform-style: preserve-3d;
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
}

.face {
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: #c96;
    border: 2px solid #114b6c;
    border-radius: 10px;
}

.front  { background-color: #10c3c5; transform: rotateY(0deg) translateZ(50px); }
.back   { background-color: #bbf7d0; transform: rotateY(180deg) translateZ(50px); }
.right  { background-color: #ffbf9c; transform: rotateY(90deg) translateZ(50px); }
.left   { background-color: #eb966f; transform: rotateY(-90deg) translateZ(50px); }
.top    { background-color: #fff5dd; transform: rotateX(90deg) translateZ(50px); }
.bottom { background-color: #012a35; transform: rotateX(-90deg) translateZ(50px); }

.shadow {
    width: 120px;
    height: 120px;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 25%;
    right: 25%;
    transform: translateX(-50%) rotateX(90deg);
    border-radius: 50%;
    filter: blur(8px);
}

/*Tetx effect on lander*/
.text-effect {
    opacity: 1;
    transition: opacity 2s ease-in-out;
}

.text-effect:empty {
    opacity: 0;
}